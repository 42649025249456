import { store } from '@webfx/core-web';

const RoutesWithoutMenu = ['mcfxClients', 'mcfxSites', 'mcfxAccounts', 'mcfxAdminCDA'];
export default RoutesWithoutMenu;

store.dispatch.router.registerRoutes([
  {
    name: 'mcfxClients',
    url: '/marketingcloudfx/clients',
  },
  {
    name: 'mcfxSites',
    url: '/marketingcloudfx/sites',
  },
  {
    name: 'mcfxAccounts',
    url: '/marketingcloudfx/accounts',
  },
  {
    name: 'mcfxDashboard',
    url: '/marketingcloudfx/:siteId?/dashboard',
  },
  {
    name: 'mcfxMonthlyOverview',
    url: '/marketingcloudfx/:siteId?/dashboard/monthly-overview',
  },
  {
    name: 'mcfxAdminCDA',
    url: '/marketingcloudfx/custom-data-alerts',
  },
  {
    name: 'mcfxCDPDashboard',
    url: '/marketingcloudfx/:siteId?/cdpfx',
  },
  {
    name: 'mcfxLeadManager',
    url: '/marketingcloudfx/:siteId?/leadmanager/:entity(overview|events|schedulerfx|forms|chats)?/:leadId?',
  },
  {
    name: 'mcfxDataOS',
    url: '/marketingcloudfx/:siteId?/data-os/:page?',
  },
  {
    name: 'mcfxProspector',
    url: '/marketingcloudfx/:siteId?/prospector/:page?',
  },
  {
    name: 'mcfxContentAnalytics',
    url: '/marketingcloudfx/:siteId?/content-analytics/:entity(overview|analysis|history)?/:page(pages|types|tags)?',
  },
  {
    name: 'mcfxContentAnalyticsByPage',
    url: '/marketingcloudfx/:siteId?/content-analytics/analysis/pages/:pageId?',
  },
  {
    name: 'mcfxContentAnalyticsByPageNew',
    url: '/marketingcloudfx/:siteId?/content-analytics/analysis/pages/new',
  },
  {
    name: 'mcfxContentAnalyticsByPageEdit',
    url: '/marketingcloudfx/:siteId?/content-analytics/analysis/pages/:pageId?/edit',
  },
  {
    name: 'mcfxCallTrackerTestComponent',
    url: '/marketingcloudfx/:siteId?/test-components',
  },
  {
    name: 'mcfxCallTracker',
    url: '/marketingcloudfx/:siteId?/calltracker/:callId?',
  },
  {
    name: 'mcfxCallTrackerSetupWizard',
    url: '/marketingcloudfx/:siteId?/calltracker/setup-wizard',
  },
  {
    name: 'mcfxCallTrackerConfig',
    url: '/marketingcloudfx/:siteId?/calltracker/configuration',
  },
  {
    name: 'mcfxCallTrackerConfigCreate',
    url: '/marketingcloudfx/:siteId?/calltracker/configuration/create',
  },
  {
    name: 'mcfxCallTrackerConfigEdit',
    url: '/marketingcloudfx/:siteId?/calltracker/configuration/:targetId',
  },
  {
    name: 'mcfxCallerAnalytics',
    url: '/marketingcloudfx/:siteId?/calleranalytics',
  },
  {
    name: 'mcfxNumberPool',
    url: '/marketingcloudfx/:siteId?/calltracker/number-pool',
  },
  {
    name: 'mcfxConversationIntel',
    url: '/marketingcloudfx/:siteId?/calltracker/conversationintel',
  },
  {
    name: 'mcfxConversationIntelAiAutomations',
    url: '/marketingcloudfx/:siteId?/calltracker/conversationintel/ai-automations',
  },
  {
    name: 'mcfxAiConversations',
    url: '/marketingcloudfx/:siteId?/calltracker/aiconversations',
  },
  {
    name: 'mcfxAiConversationsAiAutomations',
    url: '/marketingcloudfx/:siteId?/calltracker/aiconversations/ai-automations',
  },
  {
    name: 'mcfxEmailMarketing',
    url: '/marketingcloudfx/:siteId?/email-marketing',
  },
  {
    name: 'mcfxAnalyticsForecasting',
    url: '/marketingcloudfx/:siteId?/analytics/reports/leads-revenue-forecast/:type?',
  },
  {
    name: 'mcfxAnalyticsMonthlyReports',
    url: '/marketingcloudfx/:siteId?/analytics/reports/category/monthly-reports',
  },
  {
    name: 'mcfxAnalyticsReportsCategories',
    url: '/marketingcloudfx/:siteId?/analytics/reports/category/:categoryId?',
  },
  {
    name: 'mcfxAnalyticsReports',
    url: '/marketingcloudfx/:siteId?/analytics/reports/:reportId?/:tab?',
  },
  {
    name: 'mcfxAnalyticsAdmin',
    url: '/marketingcloudfx/:siteId?/analytics/admin/:child?/:id?',
  },
  {
    name: 'mcfxAnalyticsV1Configure',
    url: '/marketingcloudfx/:siteId?/analytics/configure/general',
  },
  {
    name: 'mcfxAnalyticsContributors',
    url: '/marketingcloudfx/:siteId?/analytics/contributors',
  },
  {
    name: 'mcfxAnalytics',
    url: '/marketingcloudfx/:siteId?/analytics',
  },
  {
    name: 'mcfxReportAppendix',
    url: '/marketingcloudfx/:siteId?/report-appendix/:type?/:page?',
  },
  {
    name: 'mcfxLocal',
    url: '/marketingcloudfx/:siteId?/omniseo/local/:page(scans|locations|notified)?/:id?',
  },
  {
    name: 'mcfxOmniSEO',
    url: '/marketingcloudfx/:siteId?/omniseo/:page?',
  },
  {
    name: 'mcfxReviewBoost',
    url: '/marketingcloudfx/:siteId?/reviewacceleration/:page?',
  },
  {
    name: 'mcfxCompanyTracker',
    url: '/marketingcloudfx/:siteId?/companytracker/:organizationId?',
  },
  {
    name: 'mcfxIntegrations',
    url: '/marketingcloudfx/:siteId?/integrations/:page?/:subpage?',
  },
  {
    name: 'mcfxIntegrationsEditor',
    url: '/marketingcloudfx/:siteId?/integrations/editor',
  },
  {
    name: 'mcfxIntegrationsCRMpipelines',
    url: '/marketingcloudfx/:siteId?/integrations/crm-pipelines',
  },
  {
    name: 'mcfxIntegrationsExperimental',
    url: '/marketingcloudfx/:siteId?/integrations/experimental',
  },
  {
    name: 'mcfxIntegrationsMarketingAutomation',
    url: '/marketingcloudfx/:siteId?/integrations/marketing-automation',
  },
  {
    name: 'mcfxIntegrationsDataEnrichment',
    url: '/marketingcloudfx/:siteId?/integrations/data-enrichment',
  },
  {
    name: 'mcfxIntegrationsDataStores',
    url: '/marketingcloudfx/:siteId?/integrations/data-stores',
  },
  {
    name: 'mcfxIntegrationsMachineLearning',
    url: '/marketingcloudfx/:siteId?/integrations/machine-learning',
  },
  {
    name: 'mcfxIntegrationsAnalytics',
    url: '/marketingcloudfx/:siteId?/integrations/analytics',
  },
  {
    name: 'mcfxIntegrationsAccounting',
    url: '/marketingcloudfx/:siteId?/integrations/accounting',
  },
  {
    name: 'mcfxIntegrationsAdPlatforms',
    url: '/marketingcloudfx/:siteId?/integrations/advertisement',
  },
  {
    name: 'mcfxIntegrationsEcommerce',
    url: '/marketingcloudfx/:siteId?/integrations/ecommerce',
  },
  {
    name: 'mcfxIntegrationsCommunication',
    url: '/marketingcloudfx/:siteId?/integrations/communication',
  },
  {
    name: 'mcfxSettings',
    url: '/marketingcloudfx/:siteId?/settings/:page?/:child?/:id?',
  },
  {
    name: 'mcfxCampaignUrlGenerator',
    url: '/marketingcloudfx/:siteId?/campaign-url-generator',
  },
  {
    name: 'mcfxScheduler',
    url: '/marketingcloudfx/:siteId?/scheduler',
  },
  {
    name: 'mcfxCallToAction',
    url: '/marketingcloudfx/:siteId?/call-to-action',
  },
  {
    name: 'mcfxAdTech',
    url: '/marketingcloudfx/:siteId?/ad-tech/:page(crm-retargeting|audience-targeting-pipeline|account-based-retargeting|lead-quality-bidding-pipelines|audience-targeting|unlock|geofencing|abm|ai-lookalike-prospecting)?/:tab(results)?/:campaignId?',
  },
  {
    name: 'mcfxDeepLearning',
    url: '/marketingcloudfx/:siteId?/deeplearning/:page(analyzer-detail|loading-screen|error-screen)?/:id?',
  },
  {
    name: 'mcfxLeadScorer',
    url: '/marketingcloudfx/:siteId?/leadscorer',
  },
  {
    name: 'mcfxCustomer360Audience',
    url: '/marketingcloudfx/:siteId?/customer360/audiences/:page(contacts|companies)?/:id?',
  },
  {
    name: 'mcfxCustomer360',
    url: '/marketingcloudfx/:siteId?/customer360/:page(contacts|audiences|companies|leadscorer|deals)?/:id?',
  },
  {
    name: 'mcfxCustomer360Upload',
    url: '/marketingcloudfx/:siteId?/customer360/upload',
  },
  {
    name: 'mcfxFunnels',
    url: '/marketingcloudfx/:siteId?/funnels/:page?',
  },
  {
    name: 'mcfxError',
    url: '/marketingcloudfx/:siteId?/error',
  },
  {
    name: 'nutshell',
    url: '/nutshell/:siteId?',
  },
  {
    name: 'mcfxUpdate',
    url: '/marketingcloudfx/update/:entity/:id/:action',
  },
  {
    name: 'mcfxPersonalize',
    url: '/marketingcloudfx/:siteId?/personalize/:page?/:id?',
  },
]);
